import Header from "../components/Header";
import styles from "../styles/pages/404.module.scss";

export default function Custom404() {
  return (
    <>
      <Header />
      <main className={styles.container}>
        <div className={styles.title}>
          <h1>404</h1> <h2>Página não encontrada</h2>
        </div>

        <div className={styles.duck}>
          <div className={styles.image} />
        </div>
      </main>
    </>
  );
}
